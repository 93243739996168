if (typeof Prek == 'undefined') {
    var Prek = {};
}

(function ( $ ) {

    'use strict';
    Prek.FasadForms = function(
        $inquiryformholder, $showingformholder, $speculatorformholder) {
        this.fasadInterestUrl = 'https://crm.fasad.eu/api/customerqueue' +
            '/addinterestcustomertoqueue?';

        this.fasadShowingUrl = 'https://crm.fasad.eu/api/' +
            'customerqueue/addshowingcustomertoqueue?';

        this.$inquiryformHolder = $inquiryformholder;
        this.$speculatorformHolder = $speculatorformholder;
        this.$showingformHolder = $showingformholder;
        this.$inquiryform = this.$inquiryformHolder.find('form');
        this.$speculatorform = this.$speculatorformHolder.find('form');
        this.$showingform = this.$showingformHolder.find('form');
        this.$inquiryMessage = this.$inquiryformHolder.find('div.popup-message');
        this.$showingMessage = this.$showingformHolder.find('div.popup-message');
        this.bindInquirySubmit();
        this.bindSpeculatorSubmit();
        this.bindShowingSubmit();

    };

    Prek.FasadForms.prototype.bindInquirySubmit = function () {
        var me = this;
        me.$inquiryform.on('submit', function(e) {
            e.preventDefault();
            me.addToFasadStakeholderQueue('stakeholder');
        });

    };

    Prek.FasadForms.prototype.bindSpeculatorSubmit = function() {
        var me = this;
        $(document).on('wpcf7mailsent', function(e) {
            me.addToGAN();
            me.addToFasadStakeholderQueue('speculator');
        });
    };

    Prek.FasadForms.prototype.bindShowingSubmit = function() {
        var me = this;
        me.$showingform.on('submit', function(e) {
            e.preventDefault();
            console.log('In function');
            me.addToShowing();
            console.log('In function 1');
            me.$showingform.find("input[type='submit']").prop( "disabled", true);
            console.log('In function 2');
        });
    };

    Prek.FasadForms.prototype.addToGAN = function () {
        const me = this;
        if (me.$speculatorform.length > 0) {
          const email = me.$speculatorform.find('input[name="mail"]').length ? me.$speculatorform.find('input[name="mail"]').val() : '';
          const firstname = me.$speculatorform.find('input[name="firstname"]').length ? me.$speculatorform.find('input[name="firstname"]').val() : '';
          const lastname = me.$speculatorform.find('input[name="lastname"]').length ? me.$speculatorform.find('input[name="lastname"]').val() : '';
          const data = {
            action: 'gan_add_subscriber',
            ajax_nonce: localizedVars.ajax_object.ajax_nonce,
            email: email,
            firstname: firstname,
            lastname: lastname,
          };
          me.$speculatorform.find("input[type='submit']").prop( "disabled", true);
          $.post(localizedVars.ajax_object.ajax_url, data, function ( response ) {});
        }
    };

    Prek.FasadForms.prototype.addToFasadStakeholderQueue = function(which) {
        const me = this;
        var msg = '';
        var serializedValues = '';
        if (which === 'stakeholder') {
            serializedValues = me.$inquiryform.serialize();
            msg = 'Tack för visat intresse, vi kontaktar dig inom kort.';
        } else if (which === 'speculator') {
            serializedValues = me.$speculatorform.serialize();
            msg = 'Tack för din intresseanmälan. Vi återkommer med passande objekt.';
        }
        const sendToFasadStakeholderQueue = $.ajax({
            url: this.fasadInterestUrl,
            data: serializedValues,
            dataType: 'jsonp',
        });
        me.$inquiryform.find("input[type='submit']").prop( "disabled", true);
        sendToFasadStakeholderQueue.done(function(response) {
            response = {
                message: '<h2>Tack för din intresseanmälan!</h2><p>Ansvarig mäklare kommer att kontakta dig inom kort med förslag på tid för personlig visning.</p>',
            };
            if (which === 'stakeholder') {
                me.resetForm(me.$inquiryform);
            } else if (which === 'speculator') {
                me.resetForm(me.$speculatorform);
            }
            const $modal = $('#showingModal');
            if ($modal.length) {
                $modal.modal('hide');
            }

            me.showResponse(msg);
        });
    };

    Prek.FasadForms.prototype.addToShowing = function() {

        var me = this;
        var emailAddress = me.$showingform.find('input#email').length ?
            me.$showingform.find('input#email').val() :
            'null';
        var data = me.$showingform.serialize();

        // Append chosen slot-id for showing
        var slotId = me.$showingform.find(':selected').data('slotid');
        data += '&slot=' + slotId;

        var sendToFasad = $.ajax({
            url: me.fasadShowingUrl,
            data: data,
            dataType: 'jsonp'
        });

        sendToFasad.done(function ( response ) {
            // me.$showingform.find('.ajax-loader').removeClass('is-active');
            if (response.success) {
                // me.$showingMessage.addClass('wpcf7-mail-sent-ok').append(response.message).slideDown();
                me.resetForm(me.$showingform);
                var $modal = $('#showingModal');
                if ($modal.length) {
                    $modal.modal('hide');
                }
                me.showResponse('Tack för din visningsanmälan, kontakta oss gärna om du har några frågor innan visning.');
            } else {
                var msg = (response.message === 'Visningen är fullbokad! ') ?
                    'Den här visningen är fullbokad men du är välkommen att ' +
                    'kontakta ansvarig mäklare och boka ytterligare visningar.' :
                    response.message;
                me.showResponse(msg);
            }
        });
    };


    Prek.FasadForms.prototype.resetForm = function ( $form ) {
        var me = this;
        $form.find('input[type="text"]').val('');
        $form.find('input[type="email"]').val('');
        $form.find('textarea').val('');
    };
    Prek.FasadForms.prototype.showResponse = function(message) {
        var $response = $('#message-response');
        $response.find('span.content').html(message);
        if ($response.hasClass('hidden')) {
            $response.removeClass('hidden');
        }
        $response.find('.wrede__button.wrede__button--close').
            on('click', function() {
                $response.addClass('hidden');
            });
    };

})(jQuery);

new Prek.FasadForms((jQuery)('#fasad-interest-form'),
    (jQuery)('#fasad-showing-form'), (jQuery)('#fasad-speculator-form'));
